<template>
    <section>
      <div class="w-full">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 lg:gap-4 mt-4">
            <button v-if="$can('pharmasan.logistica.configuracion.bodegas')" @click="$router.push({name: 'pharmasan.compras.logistica.configuracion.bodegas'})" class="border rounded-lg text-center hover:shadow-lg p-4 bg-white">
              <div class="w-full flex justify-center">
                <img class="w-28 rounded-md" src="../../../../../../../../public/img/bodegasIcon.jpg" alt="">
              </div>
              <div>
                <p class="font-bold my-2">
                  Bodegas
                </p>
              </div>
              <div>
                <p class="text-gray-600">
                  Listado de responsables de bodegas
                </p>
              </div>
            </button>
        </div>
        <router-view>

        </router-view>
      </div>
    </section>
  </template>
  <script>
    import { onMounted } from 'vue'
    export default {
    name: 'MenuConfiguracionLogisticaPharmasan',
    setup () {
      onMounted(() => {
      })
      return {}
    }
  }
  </script>

  <style scoped>
  </style>
